import React from 'react';

const Home = () => (
    <div id="home" style={styles.section}>
        <h1>Home</h1>
        <p>Welcome to the home section!</p>
    </div>
);

const About = () => (
    <div id="about" style={styles.section}>
        <h1>About</h1>
        <p>Learn more about us in this section.</p>
    </div>
);

const Services = () => (
    <div id="services" style={styles.section}>
        <h1>Services</h1>
        <p>These are the services we offer.</p>
    </div>
);

const Contact = () => (
    <div id="contact" style={styles.section}>
        <h1>Contact</h1>
        <p>Get in touch with us here.</p>
    </div>
);

const styles = {
    section: {
        height: '100vh', // Full screen height for each section
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#f4f4f4',
        borderBottom: '1px solid #ccc',
    },
};

export { Home, About, Services, Contact };
