import React from 'react';
import { Link } from 'react-scroll';

const HeaderComponent = () => {
    return (
        <header style={styles.header}>
            <div style={styles.container}>
                {/* Logo */}
                <Link to="home" smooth={true} duration={500} style={styles.logoLink}>
                    <img src="/SVLogo2.png" alt="Logo" style={styles.logo} />
                </Link>

                {/* Navigation Links */}
                <nav>
                    <ul style={styles.navList}>
                    <li style={styles.navItem}>
                            <Link to="home" smooth={true} duration={500}>
                                Home
                            </Link>
                        </li>
                        <li style={styles.navItem}>
                            <Link to="about" smooth={true} duration={500}>
                                About
                            </Link>
                        </li>
                        <li style={styles.navItem}>
                            <Link to="services" smooth={true} duration={500}>
                                Services
                            </Link>
                        </li>
                        <li style={styles.navItem}>
                            <Link to="contact" smooth={true} duration={500}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

const styles = {
    // Header Background and Spacing
    header: {
        backgroundColor: '#110012',
        padding: '1rem 0', // Top and bottom padding
    },

    // Inner Container for Centering
    container: {
        maxWidth: '1200px', // Limits the content width
        margin: '0 auto', // Centers the container
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Space between logo and nav links
        padding: '0 2rem', // Adds padding to the sides
    },

    // Logo Style
    logo: {
        width: '250px',
        cursor: 'pointer',
    },
    logoLink: {
        textDecoration: 'none',
    },

    // Navigation List
    navList: {
        listStyle: 'none',
        display: 'flex',
        gap: '2.5rem', // Space between navigation items
        margin: 0,
        padding: 0,
    },

    // Navigation Item
    navItem: {
        color: 'white',
        cursor: 'pointer',
        fontSize: '1.2rem', // Bigger font size
        fontWeight: 'bold', // Makes font bold
        letterSpacing: '0.1rem', // Adds spacing between letters
        fontFamily: '"Montserrat", sans-serif', // Use Montserrat font
    },
};

export default HeaderComponent;
